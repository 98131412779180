// import { CookieStorage } from 'cookie-storage'

import start from '../../../../common/microservices/loaders/lazy-microservices/with-currency'

// const cookieStorage = new CookieStorage();
// const flag = cookieStorage.getItem('__ab-multicurrency')

start(() => import('./render'), 'lazy_microservices__root', {
  lazyStoreManagerLoader: () => import('../../../../common/microservices/lazy-store-manager'),

  // switcherEnabled: flag === 'GROUP_B',
  currencySwitcherRenderLoader: () => import('./currency-switcher/render'),
  currencySwitcherRenderMountPointId: 'microservice__currency-switcher__root',
})
