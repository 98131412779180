import { Options as CalculatorOptions } from '.'

import lazyCalculator from './lazy'

import type { RenderLoader } from '../types'

interface Options extends CalculatorOptions {
  emailsCollectorRenderLoader: RenderLoader
  emailsCollectorRenderMountPointId: string
}

export default function lazyCalculatorWithEmailsCollector(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  const emailsCollectorMountPoint = document.getElementById(options.emailsCollectorRenderMountPointId) as HTMLElement

  let observer!: IntersectionObserver

  // Start calculator
  lazyCalculator(renderLoader, mountPointId, {
    lazyStoreManagerLoader: options.lazyStoreManagerLoader,

    mediaQuery: options.mediaQuery,
  })

  // Start emails collector
  if (typeof window.IntersectionObserver !== 'undefined') {
    observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        emailsCollector()
      }
    }, {
      rootMargin: "2000px",
      threshold: 1.0,
    })
    observer.observe(emailsCollectorMountPoint)
  } else {
    emailsCollector()
  }

  async function emailsCollector() {
    observer?.disconnect()

    await (await options.lazyStoreManagerLoader()).initializing;

    (await options.emailsCollectorRenderLoader())
      .render(emailsCollectorMountPoint)
  }
}
