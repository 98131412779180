import type { RenderLoader, CreateStartOptions } from '../types'

export interface Options extends CreateStartOptions {
  mediaQuery?: string
}

export default function lazyCalculator(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  const start = async () => {
    if (options.mediaQuery && !window.matchMedia(options.mediaQuery).matches) {
      return
    }

    window.removeEventListener('resize', load)

    await (await options.lazyStoreManagerLoader()).initializing

    const renderer = renderLoader()

    const mountPoint = document.getElementById(mountPointId) as HTMLElement

    (await renderer).render(mountPoint)
  }

  window.addEventListener('resize', load)

  if (localStorage.getItem('_ms_lazyloaded') === 'true') {
    load()
  } else {
    window.addEventListener('mousemove', load)
    window.addEventListener('touchstart', load)
    window.addEventListener('scroll', load)
  }

  async function load() {
    window.removeEventListener('mousemove', load)
    window.removeEventListener('touchstart', load)
    window.removeEventListener('scroll', load)

    localStorage.setItem('_ms_lazyloaded', 'true')

    await start()
  }

  if (options.mediaQuery) {
    const hideByMediaQuery = () => {
      const mountPoint = document.getElementById(mountPointId) as HTMLElement

      if (window.matchMedia(options.mediaQuery).matches) {
        mountPoint.classList.remove('hidden')
      } else {
        mountPoint.classList.add('hidden')
      }
    }

    window.addEventListener('resize', hideByMediaQuery)
  }
}
