import start from '../../../../common/microservices/loaders/order-calculator/with-emails-collector'

import '../lazy-microservices/lazy-microservices'

start(() => import('../order-calculator/render'), 'microservice__new-order__root', {
  lazyStoreManagerLoader: () => import('../../../../common/microservices/lazy-store-manager'),

  emailsCollectorRenderLoader: () => import('./render'),
  emailsCollectorRenderMountPointId: 'microservice__emails-collector__root',

  mediaQuery: '(min-width: 1200px)',
})
